import React from "react";

import { useNavigate } from 'react-router-dom';


import "../../styles/DoorStylePage/DoorStyle.css";

function DoorImagesDisplay({ header, miniDescription, imageList }) {
    const navigate = useNavigate();

    const handleDoorClick = (Collection, DoorStyle, Color, event) => {
        navigate(`/shop/${Collection}/${DoorStyle}/${Color}`);
    };

    return (
        <div className='allContainer'>
            <div className="doorTypeHeader">{header}</div>
            <div className="miniDescription">{miniDescription}</div>
            <div className="doorFrontImagesContainer">
                {imageList.map((image, index) => (
                    <div className='doorImgContainer' key={index} onClick={(event) => handleDoorClick(image.collection, image.doorStyle, image.label.replace(' ', '_'), event)}>
                        <img className="doorImg" src={image.src} alt={image.alt} />
                        <span className="doorImgText">{image.label}</span>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default DoorImagesDisplay;