import React, { useState, useEffect, useRef, useCallback} from "react";
import { useParams, useSearchParams } from "react-router-dom";

// Styles
import "../../styles/ShopPage/Shop.css";

// Components
import CabinetProductCard from "./CabinetProductCard";
import DropdownContainer from "./DropdownContainer";
import Banner from "../Banner";
import SortBy from "./SortBy";
import MiniHero from "../MiniHero";

// Variables
import  Axios from "../../utils/axios";
import { useAuth } from "../../contexts/AuthContext";
import { cabinetsData } from "../../constants/cabinetsData";

function Shop () {
  
  /*
  // auth
  const { isLoggedIn } = useAuth();

  // product params
  const { collection, doorStyle, color } = useParams();
  const [queryParams] = useSearchParams();

  
  if (!collection | !doorStyle | !color) {
    collection = "Capital";
    doorStyle = "Shaker";
    color = "White";
  }
  
  const [cabinetsData, setCabinetsData] = useState([]);
  const [page, setPage] = useState(1);  // Track the current page
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);  // Track if there's more data to load

  useEffect(() => {
    // Define an async function inside the useEffect to fetch data
    const fetchData = async () => {
      try {
          setLoading(true);
          const response = await Axios.get(`/api/cabinet/${collection}/${doorStyle}/${color}`, { params: queryParams });
          setCabinetsData(prevData => [...prevData, ...response.data]);  // Append new data 
          setHasMore(response.data.length > 0);
          setLoading(false);
      } catch (error) {
        // do nothing

      }
    };
    
    fetchData();
  }, [page, collection, doorStyle, color, queryParams]);

  // Callback function to detect when to load more items if logged in
  const handleScroll = useCallback(() => {    
    if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight || loading || !hasMore) return;
    
    if (hasMore){
      setPage(prevPage => prevPage + 1);
      queryParams.set("p", page + 1);
    }
  }, [loading, hasMore]);

  // Effect to attach event listener
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);
  */
  
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [sortOption, setSortOption] = useState("default");
  const [filterType, setFilterType] = useState(null);
  const [filteredAndSortedItems, setFilteredAndSortedItems] = useState(cabinetsData);
  const filterRef = useRef(null);

  // Close filter dropdown when clicking outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setIsFilterOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [filterRef]);

  // Scroll to top of page when component mounts
  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  function toggleFilterDropdown() {
    setIsFilterOpen(!isFilterOpen);   
  }

  // Filter and sort items
  useEffect(() => {
    let filteredItems = cabinetsData;
  if (filterType) {
    filteredItems = cabinetsData.filter((item) =>
      item.cabinet_type.toLowerCase().includes(filterType.toLowerCase())
    );
  }
    switch (sortOption) {
      case "A-Z":
        filteredItems.sort((a, b) => a.cabinetid.localeCompare(b.cabinetid));
        break;
      case "Z-A":
        filteredItems.sort((a, b) => b.cabinetid.localeCompare(a.cabinetid));
        break;
      case "low-to-high":
        filteredItems.sort((a, b) => a.price - b.price);
        break;
      case "high-to-low":
        filteredItems.sort((a, b) => b.price - a.price);
        break;
      default:
        break;
    }
    setFilteredAndSortedItems(filteredItems);
  }, [sortOption, filterType, cabinetsData]);

  function handleSortChange(event) {
    setSortOption(event.target.value);
  }

  
  return (
    <section className="shop">
      {/* HERO SECTION */}
      <MiniHero pageName="Shop" />
  
      {/* HORIZONTAL BAR WITH FILTER AND SORTING */}
      <div className="control-panel">
        <div className="filter-sort">
          <div className="filter" onClick={toggleFilterDropdown}>
            <img 
              ref={filterRef}
              src="/system-uicons_filtering.svg"
              alt="Filter Icon"
            />
            <span>Filter</span>
          <DropdownContainer
            filterRef={filterRef}
            type={filterType}
            isFilterOpen={isFilterOpen}
            setFilterType={setFilterType}
            setIsFilterOpen={setIsFilterOpen}
          />
          </div> 
        </div>
        <div className="results">Showing 1-16 of 32 results</div>
        <SortBy handleSortChange={handleSortChange}/>
      </div>

      {/* ITEMS SECTION */}
      <div className="cabinet-products">
        {filteredAndSortedItems.map((cabinet) => (
          <CabinetProductCard key={cabinet.cabinetid} cabinet={cabinet} />
        ))}
      </div>

      
      {/* FOOTER */}
      <Banner />
    </section>
  );
}

export default Shop;