import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { AuthProvider } from "./contexts/AuthContext";
import CssBaseline from "@mui/material/CssBaseline";
import "@fontsource/roboto";
import "./index.css";

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.Fragment>
    <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
    <React.StrictMode>
      <AuthProvider>
        <CssBaseline />
        <App />
      </AuthProvider>
    </React.StrictMode>
  </React.Fragment>
)
