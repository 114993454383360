import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import "../../styles/Home/ProductsSection.css";

import Axios from "../../utils/axios";
import CabinetProductCard from "../ShopPage/CabinetProductCard";
import { cabinetsData } from "../../constants/cabinetsData";

function ProductsSection() {
  /*const [cabinetsData, setCabinetsData] = useState([]);

  useEffect(() => {
    // Define an async function inside the useEffect to fetch data
    const fetchData = async () => {
      try {
        const response = await Axios.get('/api/cabinet/Capital/Shaker/White', {
          params: {
            p: 1,
            sort: 'price',
          },
        });

        setCabinetsData(prevData => [...prevData, ...response.data]);  // Append new data
      } catch (error) {
        // Log the actual error
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.error('Error fetching data:', error.response.data);
        } else if (error.request) {
          // The request was made but no response was received
          console.error('Error fetching data: No response received');
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error('Error fetching data:', error.message);
        }
      }
    };
    fetchData();
  }, []);*/


  return (
    <section className="products-section">
      <div className="products-title">
        <h1>Our Products</h1>
      </div>
      <div className="cabinet-products">
        {cabinetsData.slice(0, 8).map((cabinet) => (
          <CabinetProductCard cabinet={cabinet} key={cabinet.id} />
        ))}
      </div>
      <div className="show-more-link-container">
        <Link to="/doorstyle" className="show-more-link">
          <button className="show-more-btn">Show More</button>
        </Link>
      </div>
    </section>
  );
}

export default ProductsSection;
